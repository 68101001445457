.table-container {
  overflow-x: auto;
  overflow-y: auto;
  white-space: nowrap;
  height: 700px;
  padding-right: 0;
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 1;
}

.vertical-pagination {
  width: 100px;
}

.action_data {
  white-space: 'pre-wrap';
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
}